import "./about.css";

import React from "react";
import { Helmet } from "react-helmet";

import BackgroundEffect from "../general/BackgroundEffect";
import StandardButton from "../general/StandardButton";

const FEATURED_NEWS = {
  type: "Press",
  title: "Avior Labs strengthens UAV capabilities with new partnerships",
  body: "Unmanned aerial vehicle (UAV) company Avior Labs signed agreements with companies at the Africa Aerospace and Defence (AAD) 2024 exhibition to further strengthen UAV research and development, components, and manufacturing. On the fist day of the show on 18 September, Avior Labs signed a research and development (R&D) partnership agreement with LambdaG.  The companies said the agreement is aimed at advancing communication technologies for UAVs and will focus on joint research, design, and development of antennas for UAV communication systems used in aerospace and defence applications.",
  date: "27 September 2024",
  image: "/images/about/news/news-8.jpg",
  link: "https://www.defenceweb.co.za/aerospace/aerospace-aerospace/avior-labs-strengthens-uav-capabilities-with-new-partnerships/",
  linkLabel: "Read more",
  alignImage: "50% 20%",
};

const NEWS_ITEMS = [
  {
    type: "Blog",
    title: "Announcing our strategic partnership with L&A Risk Management Systems",
    body: "The third agreement signed, Avior Labs (Pty) Ltd is thrilled to announce our strategic partnership with L&A Risk Management Systems (Pty) Ltd (RMS) to bring the eCLIPSE-S Drone Operations Management System to life.",
    date: "22 September 2024",
    image: "/images/about/news/news-9.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7242812245359964160/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "A landmark agreement with Aerotechnic Pty Ltd",
    body: "Avior Labs (Pty) Ltd is thrilled to announce a landmark agreement with Aerotechnic Pty Ltd, effective 18 September 2024. This is the second of three partnership agreements signed by Avior Labs this week at the African Aerospace and Defence Expo.",
    date: "18 September 2024",
    image: "/images/about/news/news-10.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7242781735787057152/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "R&D partnership signed with LambdaG",
    body: "Avior Labs (Pty) Ltd and LambdaG (Pty) Ltd are excited to announce the signing of a Research and Development (R&D) partnership aimed at advancing communication technologies for Unmanned Aerial Vehicles.",
    date: "18 September 2024",
    image: "/images/about/news/news-11.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7242775658718711808/",
    linkLabel: "Read more",
  },
  {
    type: "Event",
    title: "Gearing up for the 2024 AAD Expo",
    body: "Avior Labs (Pty) Ltd is gearing up for the premier exhibition of air, land and sea technologies on the African continent, the 2024 African Aerospace and Defence (AAD) Expo. Find us in Hangar 4 - Exciting new opportunities await!",
    date: "17 September 2024",
    image: "/images/about/news/news-12.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7241694957621678080/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "Welcome to our new website!",
    body: "We are delighted to unveil the official website for Avior Labs (Pty) Ltd, a proudly 100% South African drone design and manufacturing company. At Avior Labs we pride ourselves on delivering innovative, cutting-edge drone technology.",
    date: "15 September 2024",
    image: "/images/about/news/news-13.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7239605729119158273/",
    linkLabel: "Read more",
  },
  {
    type: "Event",
    title: "Avior Labs to be featured at the 2024 AAD Expo",
    body: "We are excited to be featured at the 2024 Africa Aerospace and Defence Expo in Pretoria, South Africa. We will be showcasing the Elevation-G UAV and our Wildlife e-Census solution.",
    date: "18-20 September 2024",
    image: "/images/about/news/news-1.jpg",
    link: "https://www.aadexpo.co.za/",
    linkLabel: "AAD Website",
  },
  {
    type: "Blog",
    title: "New Feature Development: From Concept to Simulation",
    body: "Avior Labs (Pty) Ltd is committed to developing innovative and cutting-edge drone technology. Our flight test process is a critical part of our product development cycle. Avior Labs’ Kreelan Padayachee shares insight into the process.",
    date: "7 September 2024",
    image: "/images/about/news/news-14.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7236599753478684672/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "Taking flight efficiency to new heights...",
    body: "Avior Labs (Pty) Ltd's Crispin McKenzie is currently working on his Master's degree in collaboration with Lightweight Structures (Pty) Ltd and the Aeronautical Engineering Department at Wits University. Here is a glimpse into his research.",
    date: "2 September 2024",
    image: "/images/about/news/news-15.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7232657884952580098/",
    linkLabel: "Read more",
  },
  {
    type: "Event",
    title: "Crispin McKenzie presents at the 2024 AeSSA conference",
    body: "Avior Labs (Pty) Ltd believes that continuous research and development is crucial for driving innovation and maintaining a competitive edge in the drone industry. Crispin McKenzie presented a research paper at the Aeronautical Society of South Africa (AeSSA) conference.",
    date: "7 August 2024",
    image: "/images/about/news/news-16.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7222529699514613761/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "Elevate your aerial operations with ELEVATION",
    body: "Avior Labs (Pty) Ltd's ELEVATION is a versatile, fully autonomous, multi-role fixed-wing VTOL drone that optimises utilisation and reduces fleet requirements. It is fully electric with 90 minutes of useful flight time in its standard configuration.",
    date: "3 August 2024",
    image: "/images/about/news/news-17.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7220036805142675456/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "Customisation is key",
    body: "In the rapidly evolving field of drone technology, customisation is key to unlocking new possibilities and enhancing performance. Find out how Avior Labs (Pty) Ltd is pushing the boundaries of drone technology through customisation.",
    date: "20 July 2024",
    image: "/images/about/news/news-18.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7217498196774690817/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "New feature approval process",
    body: "We invite you on a journey of discovery on the first Thursday of every month. Join us in the lab and on the runway as we explore the test flight process. Before we begin, let's step back and look at the bigger picture. Our flight test team created a flow diagram illustrating our test flight process.",
    date: "4 July 2024",
    image: "/images/about/news/news-19.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7214543746242125824/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "An introduction to Avior Labs",
    body: "Avior Labs (Pty) Ltd develops innovative products and technologies to serve the needs of unmanned aerial system operators and service providers. We specialise in designing and manufacturing highly efficient commercial unmanned aerial vehicles, associated technologies, and software.",
    date: "1 July 2024",
    image: "/images/about/news/news-20.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7209161751542517762/",
    linkLabel: "Read more",
  },
  {
    type: "Blog",
    title: "A new team member - welcome Zelmarie",
    body: "It has been a momentous week at Avior Labs (Pty) Ltd as we expanded our internal resources. With our anticipated growth, we welcome onboard Zelmarie Kruger as our new Software Developer. Zelmarie brings a collaborative spirit and a wealth of wealth of experience.",
    date: "20 June 2024",
    image: "/images/about/news/news-22.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7207290736722010113/",
    linkLabel: "Read more",
  },
  {
    type: "Event",
    title: "Avior Labs at the Drone & Unmanned Aviation Conference",
    body: "Soaring to new heights with proudly South African drone technology! The Avior Labs (Pty) Ltd Team recently attended the Drone & Unmanned Aviation Conference 2024 in Johannesburg. Avior Labs is proud to have been the only attending company to offer an authentic South African VTOL!",
    date: "15 June 2024",
    image: "/images/about/news/news-23.jpg",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7206330753796730881/",
    linkLabel: "Read more",
  },
];

const About = () => {

  const [visiblePosts, setVisiblePosts] = React.useState(6);
  const increaseVisiblePosts = React.useCallback(() => {
    setVisiblePosts((prev) => prev + 6);
  }, []);

  const hasMorePosts = NEWS_ITEMS.length > visiblePosts;

  return (
    <main>
      <Helmet>
        <title>About | Avior Labs</title>
        <meta
          name={"description"}
          content={"Learn more about Avior Labs, our history, and our mission to empower businesses with innovative drone technology."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"about-header"}>
        <h1>ABOUT</h1>
        <img
          className={"about-header-img"}
          src={"/images/about/about-header.jpg"}
        />
      </header>

      <section className={"about-history-section standard-width"}>
        <h2 className={"section-head"}>Our history</h2>
        <p className={"about-intro-text"}>
          Avior Labs is an aerospace company that designs, develops, and manufactures unmanned
          aerial vehicles (UAV) and associated solutions for commercial and humanitarian applications.
          The company was founded by two experienced aeronautical engineers with a passion for all
          things flying and an economist with a love for research and analytics.
          <br /><br />
          The idea to start a new
          company was conceived out of the desire to apply decades of combined experience in
          aerospace R&D and consulting to develop and produce our own products and solutions.
          Recognising the enormous opportunities for drone applications in sub-Saharan Africa and the
          potential to apply the founders’ unique set of skills to exploit these opportunities sealed the
          deal. Avior Labs was born.
        </p>

        <div className={"timeline"}>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>January 2019</h3>
              <p>The company is launched by two aeronautical engineers and an economist with a passion for aerospace.</p>
              <img src={"/images/about/timeline/Timeline1_OG.png"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>February 2019</h3>
              <p>
                Work starts on the development of the Avior Labs Universal Simulation Environment (USE) – an extremely powerful, fully
                networked simulation environment developed from first principles, implemented in C++ and providing multi-layered
                fidelity for the modelling of the dynamics of a single or many interacting vehicles or entities.
              </p>
              <img src={"/images/about/timeline/Timeline2_USE.png"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>May 2019</h3>
              <p>Conceptual studies start on the development of a small VTOL UAV for the commercial market.</p>
              <img src={"/images/about/timeline/Timeline3_conceptual_studies.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>March 2020</h3>
              <p>
                Work on the integration of a software-in-the-loop simulation (SILS) and hardware-in-the-loop
                simulation (HILS) capability in USE matures – we can now run the actual firmware used on
                UAVs or other vehicles in fully interfaced independent threads, or on the actual hardware.
              </p>
              <img src={"/images/about/timeline/Timeline4_SILS.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>December 2020</h3>
              <p>
                The user requirement specification (URS) for the Elevation VTOL UAV is issued, marking
                the start of its development.
              </p>
              <img src={"/images/about/timeline/Timeline5_Elevation_URS.png"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>October 2021</h3>
              <p>Flight testing on the first of many flying laboratories starts – the Ascension Lift Rig.</p>
              <img src={"/images/about/timeline/Timeline6_Ascension_lift_rig_option2.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>April 2022</h3>
              <p>First flight testing starts on the SkyEye flying laboratory.</p>
              <img src={"/images/about/timeline/Timeline7_SkyEye.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>November 2022</h3>
              <p>First flight of the Elevation VTOL prototype.</p>
              <img src={"/images/about/timeline/Timeline8_Elevation_first_flight_option1.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>June 2023</h3>
              <p>First flight of the production proof-of-concept Elevation airframe.</p>
              <img src={"/images/about/timeline/Timeline9_Elevation_production_option1.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>August 2023</h3>
              <p>SACAA grants Elevation a Proving Flight Authority. First flight away from our home base.</p>
              <img src={"/images/about/timeline/Timeline10_Elevation_away_from_home.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>November 2023</h3>
              <p>Initial R&D commences on Callisto – our next generation long endurance VTOL UAV.</p>
              <img src={"/images/about/timeline/Timeline11_Callisto_option2.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>January 2024</h3>
              <p>Production on a pre-production batch of 4 additional Elevation airframes commences.</p>
              <img src={"/images/about/timeline/Timeline12_production.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>April 2024</h3>
              <p>Avior Labs makes an agreement with the first Elevation operator to conduct joint operational testing on the airframes.</p>
              <img src={"/images/about/timeline/Timeline12.jpg"} />
            </div>
          </div>
        </div>
      </section>

      <section className={"about-team-header"}>
        <img
          className={"about-team-header-bg"}
          src={"/images/about/team-header-1.jpg"}
        />

        <div className={"standard-width"}>
          <h2 className={"section-head"}>Meet the team</h2>
        </div>
      </section>

      <section className={"about-team-section"}>
        <div className={"standard-width"}>
          <div className={"team-cont"}>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-bennie.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Dr Bennie Broughton</h3>
                <p>Managing Director and Co-Founder</p>
                <p>PhD Aerospace Engineering (Illinois)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-kreelan.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Kreelan Padayachee</h3>
                <p>Technical Director and Co-Founder</p>
                <p>MSc Aeronautical Engineering (Wits)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-prinal.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Dr Prinal Naidoo</h3>
                <p>Director: Global Strategy</p>
                <p>PhD Aeronautical Engineering (Wits)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-lena.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Lena Broughton</h3>
                <p>Financial Director and Co-Founder</p>
                <p>MBA (Henley), MSc Technology Management (UP)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-crispin.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Crispin McKenzie </h3>
                <p>Aeronautical Engineer</p>
                <p>BSc Aeronautical Engineering (Wits)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-zelmarie.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Zelmarie Kruger</h3>
                <p>Software Engineer</p>
                <p>BScHons Computer Sciences and Information Technology (NWU)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-ntokozo.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Ntokozo Mahlangu</h3>
                <p>Assembly, Integration and Test (AIT) Technician</p>
                <p>Diploma in Mechanical Engineering (VUT)</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"news-section"}>
        <div className={"standard-width"}>
          <h2 className={"section-head"}>Latest news</h2>
          <div className={"news-item-featured"}>
            <img
              src={FEATURED_NEWS.image}
              style={{ objectPosition: FEATURED_NEWS.alignImage ? FEATURED_NEWS.alignImage : "center" }}
            />
            <div className={"news-item-featured-content"}>
              <p className={"news-type"}>{FEATURED_NEWS.type}</p>
              <h3 className={"news-head"}>{FEATURED_NEWS.title}</h3>
              <p className={"news-body"}>{FEATURED_NEWS.body}</p>
              {
                (FEATURED_NEWS.link) && (
                  <a
                    href={FEATURED_NEWS.link}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <StandardButton
                      label={FEATURED_NEWS.linkLabel}
                      className={"news-link"}
                    />
                  </a>
                )
              }
              <p className={"news-date"}>{FEATURED_NEWS.date}</p>
            </div>
          </div>

          <div className={"news-cont"}>
            {
              NEWS_ITEMS.map((news, index) => {
                if (index >= visiblePosts) {
                  return null;
                }
                return (
                  <div
                    key={index}
                    className={"news-item standard-box"}
                  >
                    <img
                      src={news.image}
                      style={{ objectPosition: FEATURED_NEWS.alignImage ? FEATURED_NEWS.alignImage : "center" }}
                    />
                    <div className={"news-item-content"}>
                      <p className={"news-type"}>{news.type}</p>
                      <h3 className={"news-head"}>{news.title}</h3>
                      <p className={"news-body"}>{news.body}</p>
                      {
                        (news.link) && (
                          <a
                            href={news.link}
                            target={"_blank"}
                            rel={"noreferrer"}
                            className={"news-link fixed"}
                          >
                            {news.linkLabel}
                          </a>
                        )
                      }
                      <p className={"news-date"}>{news.date}</p>
                    </div>
                  </div>
                );
              })
            }
          </div>
          {
            hasMorePosts && (
              <StandardButton
                label={"Load more"}
                className={"news-load-more"}
                onClick={increaseVisiblePosts}
              />
            )
          }
        </div>
      </section>
    </main>
  );
};

export default About;